import {
  DayOfTheWeek,
  Driver,
  RecurrenceDetail,
  RecurrenceScheduleType,
  ShipmentStopType,
  ShipmentTemplate,
  ShipmentTemplateEdge,
  StopTemplate,
  TimeWindowTimeOfDay,
} from "@api/graphql/generated/generated-types";
import { ScheduleDisplay, ShipmentTemplateRow } from "../types";
import { makeCustomerDisplayName } from "@src/shipments/utils/shipmentRowFormatter";
import moment from "moment";
import { dayAbbreviations } from "@src/orders/create/utils/DateConstants";

export function makeShipmentTemplateRows(
  shipmentTemplateEdges: ShipmentTemplateEdge[]
): ShipmentTemplateRow[] {
  if (shipmentTemplateEdges === null || shipmentTemplateEdges === undefined) {
    return [];
  }
  return shipmentTemplateEdges
    .filter(
      (shipmentTemplateEdge) =>
        shipmentTemplateEdge.node !== null &&
        shipmentTemplateEdge.node !== undefined
    )
    .map((shipmentTemplateEdge) => {
      const shipmentTemplate: ShipmentTemplate = shipmentTemplateEdge.node!;

      const pickUp = findStopTemplateByType(
        shipmentTemplate.stopTemplates,
        ShipmentStopType.PickUp
      );
      const dropOff = findStopTemplateByType(
        shipmentTemplate.stopTemplates,
        ShipmentStopType.DropOff
      );
      const pickUpStopTemplates = shipmentTemplate.stopTemplates.filter(
        (stop) => stop.type === ShipmentStopType.PickUp
      );
      const dropOffStopTemplates = shipmentTemplate.stopTemplates.filter(
        (stop) => stop.type === ShipmentStopType.DropOff
      );
      return {
        key: shipmentTemplate.id,
        id: shipmentTemplate.id,
        scheduleDisplay: makeScheduleDisplay(shipmentTemplate),
        dateRangeDisplay: makeDateRangeDisplay(shipmentTemplate),
        customerDisplayName: makeCustomerDisplayName(
          shipmentTemplate.order.endCustomer
        ),
        shipmentType: shipmentTemplate.type || "",
        serviceType: shipmentTemplate.order.serviceType?.name || "",
        pickup: {
          city: pickUp?.city || "",
          streetAddress: pickUp?.streetAddress || "",
          timeWindowDisplay: displayTimeWindowOfDay(pickUp?.timeWindow),
          stopTemplates: pickUpStopTemplates,
        },
        dropoff: {
          city: dropOff?.city || "",
          streetAddress: dropOff?.streetAddress || "",
          timeWindowDisplay: displayTimeWindowOfDay(dropOff?.timeWindow),
          stopTemplates: dropOffStopTemplates,
        },
        package: {
          numPackages: shipmentTemplate.numPackages || undefined,
          packageSize: shipmentTemplate.packageSize || "",
          packageWeightInPounds:
            shipmentTemplate.packageWeightInPounds || undefined,
        },
        driver: shipmentTemplate.driver as Driver | undefined,
        price: shipmentTemplate.order?.priceOverride || undefined,
        order: shipmentTemplate.order,
        shipmentTemplate: shipmentTemplate,
      };
    });
}

function makeScheduleDisplay(
  shipmentTemplate: ShipmentTemplate
): ScheduleDisplay {
  const { recurrenceDetail } = shipmentTemplate.order;

  if (!recurrenceDetail) {
    return { title: "", detail: "" };
  }

  const { scheduleType, weeklySchedule, monthlySchedule, biweeklySchedule } =
    recurrenceDetail;

  const formatWeekDays = (days: string[]) =>
    days.map((day) => dayAbbreviations[day] || day).join(", ");

  switch (scheduleType) {
    case RecurrenceScheduleType.Daily:
      return { title: "Every Day", detail: "" };

    case RecurrenceScheduleType.CustomDaysOfTheMonth:
      const monthlyDetail = monthlySchedule?.days
        .map(displayDayOfMonth)
        .join(", ");
      return { title: "Monthly", detail: `On every ${monthlyDetail}` };

    case RecurrenceScheduleType.CustomDaysOfTheWeek:
      const weeklyDetail = formatWeekDays(weeklySchedule?.weekDays ?? []);
      return { title: "Weekly", detail: `On every ${weeklyDetail}` };

    case RecurrenceScheduleType.Biweekly:
      const startDate = biweeklySchedule?.startDateISO8601;
      const biweeklyDetail = startDate
        ? moment(startDate).format("dddd")
        : "No start date set";
      return { title: "Biweekly", detail: `Every other ${biweeklyDetail}` };

    case RecurrenceScheduleType.MonthlyOnFirst:
      const firstDetail = formatWeekDays(weeklySchedule?.weekDays ?? []);
      return {
        title: "Monthly",
        detail: `Occurs on the first ${firstDetail} of every month`,
      };

    case RecurrenceScheduleType.MonthlyOnLast:
      const lastDetail = formatWeekDays(weeklySchedule?.weekDays ?? []);
      return {
        title: "Monthly",
        detail: `Occurs on the last ${lastDetail} of every month`,
      };

    default:
      return { title: "", detail: "" };
  }
}

function makeDateRangeDisplay(shipmentTemplate: ShipmentTemplate): string {
  const recurrenceDetail: RecurrenceDetail | undefined | null =
    shipmentTemplate.order.recurrenceDetail;
  if (recurrenceDetail === null || recurrenceDetail === undefined) {
    return "";
  }

  if (
    recurrenceDetail.startDateISO8601 === null &&
    recurrenceDetail.endDateISO8601 === null
  ) {
    return "";
  }
  if (recurrenceDetail.startDateISO8601 === null) {
    return `Until ${
      recurrenceDetail.endDateISO8601
        ? moment(recurrenceDetail.endDateISO8601).format("YYYY-MM-DD")
        : ""
    }`;
  }
  if (recurrenceDetail.endDateISO8601 === null) {
    return `Starting ${moment(recurrenceDetail.startDateISO8601).format(
      "YYYY-MM-DD"
    )}`;
  }
  return `Between ${moment(recurrenceDetail.startDateISO8601).format(
    "YYYY-MM-DD"
  )} and ${
    recurrenceDetail.endDateISO8601
      ? moment(recurrenceDetail.endDateISO8601).format("YYYY-MM-DD")
      : ""
  }`;
}

function displayDayOfWeek(dayOfWeek: DayOfTheWeek): string {
  switch (dayOfWeek) {
    case DayOfTheWeek.Mon:
      return "Monday";
    case DayOfTheWeek.Tue:
      return "Tuesday";
    case DayOfTheWeek.Wed:
      return "Wednesday";
    case DayOfTheWeek.Thu:
      return "Thursday";
    case DayOfTheWeek.Fri:
      return "Friday";
    case DayOfTheWeek.Sat:
      return "Saturday";
    case DayOfTheWeek.Sun:
      return "Sunday";
  }
}

function displayDayOfMonth(day: number): string {
  if (day === 1) {
    return "1st";
  } else if (day === 2) {
    return "2nd";
  } else if (day === 3) {
    return "3rd";
  } else {
    return `${day}th`;
  }
}

export function displayTimeWindowOfDay(
  timeWindow: TimeWindowTimeOfDay | undefined
): string {
  if (!timeWindow) {
    return "";
  }
  const openingTime: string | undefined =
    timeWindow.openSecondsSinceMidnightUtc != null &&
    timeWindow.openSecondsSinceMidnightUtc != undefined
      ? displayTimeForSecondsSinceMidnightUtc(
          timeWindow.openSecondsSinceMidnightUtc
        )
      : undefined;
  const closingTime: string | undefined =
    timeWindow.closeSecondsSinceMidnightUtc != null &&
    timeWindow.closeSecondsSinceMidnightUtc != undefined
      ? displayTimeForSecondsSinceMidnightUtc(
          timeWindow.closeSecondsSinceMidnightUtc
        )
      : undefined;

  if (openingTime === undefined && closingTime === undefined) {
    return "No time window";
  }
  if (openingTime === undefined) {
    return `Due ${closingTime}`;
  }
  if (closingTime === undefined) {
    return `Ready ${openingTime}`;
  }
  return `${openingTime} - ${closingTime}`;
}

function displayTimeForSecondsSinceMidnightUtc(
  secondsSinceMidnightUtc: number
): string {
  const today = moment().utc().startOf("day");
  const exactMoment = today.add(secondsSinceMidnightUtc, "seconds");
  return moment(exactMoment).local().format("h:mma");
}

function findStopTemplateByType(
  stopTemplates: StopTemplate[],
  type: ShipmentStopType
): StopTemplate | undefined {
  return stopTemplates.find((stop: StopTemplate) => stop.type === type);
}
